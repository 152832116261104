<template>
	<div class="container">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>角色信息</span>
				</div>
				<div class="content">
					<el-form-item label="角色名称：" :inline-message="true" prop="roleName">
						<el-input
							v-model="ruleForm.roleName"
							style="width: 300px;"
							placeholder="最多输入20个字"
							maxlength="20"
						></el-input>
					</el-form-item>
					<el-form-item label="角色备注：" :inline-message="true" prop="remarks">
						<el-input
							type="textarea"
							:rows="2"
							placeholder="请输入内容,最多输入300字"
							:max="300"
							v-model="ruleForm.remarks"
							:show-word-limit="true"
						></el-input>
					</el-form-item>
				</div>
			</el-card>
		</el-form>

		<el-card class="box-card" style="margin-bottom:90px">
			<div slot="header" class="clearfix">
				<span>PC后台菜单权限</span>
			</div>
			<div class="content">
				<el-tree
					:data="menuList"
					:props="{children: 'subMenus', label: 'authorityName'}"
					show-checkbox
					node-key="id"
					highlight-current
					:default-checked-keys="jurisdictionList"
					ref="tree"
				></el-tree>
			</div>
		</el-card>

		<div class="box-card footer">
			<el-button style="width:240px;" @click="cancelSupiler()">取消</el-button>
			<el-button
				style="width:240px;"
				type="primary"
				@click="saveSupiler('ruleForm')"
				:loading="loading"
			>保存</el-button>
		</div>
	</div>
</template>

<script>
import {
	addRole,
	editRole
} from "@/api/api.js";
import { Mallalljurisdict } from "@/api/TurnTomySelf.js";
// import { createLogger } from 'vuex';

export default {
	data () {
		return {
			menuList: [],
			jurisdictionList: [],
			loading: false,
			id: "",
			ruleForm: {
				roleName: "",
				remarks: '',
			},
			rules: {
				roleName: [
					{
						required: true,
						message: "请输入角色名称",
						trigger: "blur",
					},
				],
			},

			//选择门店
			visible: false,
			keywords: "",
			shopList: [],
			tagList: [],
			selectedList: [],
			tag: "",
			pageIndex: 1,
			pageSize: 10,
			total: 0,
			selectKey: [],
			selectedKey: [],
			// 选择表单
			signList: [],
			selectedTagList: [],
			tagKeyWords: "",
			tagVisible: false,
			tagPage: {
				size: 10,
				total: 0,
				current: 1,
			},
		};
	},
	computed: {
	},
	created () {
		this.id = this.$route.query.id || 0;
		this.ruleForm.roleName = this.$route.query.name || '';
		this.ruleForm.remarks = this.$route.query.remarks || '';
		this.getMenlist();
	},
	beforeMount () {
	},
	methods: {
		//获取所有权限
		async getMenlist () {
			try {
				let data = {
					roleId: this.id,
				};
				let result = await Mallalljurisdict(data);

				if (result.success) {
					console.log(new Date());
					this.menuList = this.filterChecked(result.data);
					console.log(new Date());
					console.log("后台提供的所有权限", this.menuList);
					this.jurisdictionList = this.unique(this.jurisdictionList);
					setTimeout(() => {
						if (this.$refs.tree) {
							this.$refs.tree.setCheckedKeys(this.jurisdictionList);
						}
					}, 260);
				}
			} catch (err) {
				console.log(err);
			} finally {
				console.log("finally");
			}
		},
		filterChecked (list, record) {
			return list.map((item) => {
				if (record) {
					var obj = JSON.parse(JSON.stringify(record));
					// if (!item.parentList){
					// 	item.parentList = [];
					// }
					item.parentList = obj.parentList || [];
					item.parentList.push(obj.id);
					item.ParentId = obj.id;
				}
				if (item.checked && item.subMenus.length == 0) {
					this.jurisdictionList.push(item.id);
				}
				if (item.subMenus && item.subMenus.length) {
					this.filterChecked(item.subMenus, item);
				}
				return item;
			});
		},
		//取消保存
		cancelSupiler () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: "/setting/roleManage/index",
			});
		},
		// 保存
		saveSupiler (formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.sureSave();
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 数组去重
		unique (arr) {
			const res = new Map();
			return arr.filter((arr) => !res.has(arr) && res.set(arr, 1));
		},
		async sureSave () {
			this.loading = true
			let arrs = this.$refs.tree.getCheckedNodes(false, true);
			this.selectKey = arrs.map((ite) => {
				return ite.id;
			});
			// console.log(this.selectKey);
			let params = {
				id: this.id,
				roleName: this.ruleForm.roleName,
				status: 1,
				authIds: this.selectKey.join(','),
				remarks: this.ruleForm.remarks,
			}
			console.log(this.selectKey);
			let res = '';
			if (this.id != undefined && this.id != 0) {
				res = await editRole(params);
			} else {
				res = await addRole(params);
			}

			if (res.success) {
				this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
					(item) => {
						return item.path != this.$route.path;
					}
				);
				this.$router.push({
					path: "/setting/roleManage/index",
				});
			} else {
				this.$message({
					showClose: true,
					type: 'error',
					message: res.alertMsg
				});
			}
			this.loading = false;
		},
	},
};
</script>

<style lang="less" scoped>
.container {
	// padding: 10px;
	width: 100%;
	overflow: hidden;

	/deep/.el-textarea {
		width: 500px;
	}
}

.upload-avatar {
	width: 145px;
	height: 145px;
	object-fit: contain;
}

.footer {
	margin-left: -10px;
	background-color: #fff;
	z-index: 999;
	width: 100%;
	margin-right: 10px;
	height: 80px;
	text-align: center;
	line-height: 80px;
	position: fixed;
	bottom: 0px;
}

.table-box {
	overflow: hidden;
	margin-top: 20px;
}

.search {
	display: flex;
}
</style>
